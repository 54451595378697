:root {
  --themeColor: rgb(234, 0, 95);
  --backgroundOne: rgb(0, 0, 0);
  --backgroundOne80: rgba(0, 0, 0, 0.8);
  --backgroundOne60: rgba(0, 0, 0, 0.6);
  --backgroundOne40: rgba(0, 0, 0, 0.4);
  --backgroundOne72: rgba(0, 0, 0, 0.72);
  --backgroundTwo: rgb(19, 20, 24);
  --backgroundTwo60: rgba(19, 20, 24, 0.6);
  --backgroundThree: rgb(28, 28, 36);
  --textPrimary: rgb(255, 255, 255);
  --textSecondary: rgb(127, 127, 129);
  --iconsAndText: rgb(255, 255, 255);
  --iconAndTextReproved: rgb(0, 0, 0);
  --stepReproved: rgb(255, 215, 0);
  --barColor: rgb(0, 0, 0);
  --barColor85: rgba(0, 0, 0, 0.85);
  --barColor50: rgba(0, 0, 0, 0.5);
  --iconAndText: rgb(140, 140, 140);
  --hoverIconAndText: rgb(255, 255, 255);
  --buttonPrimary: rgb(234, 0, 95);
  --buttonSecondary: rgb(148, 163, 184);
  --buttonTertiary: rgb(255, 255, 255);
  --inputIcon: rgb(107, 114, 128);
  --alertBackground: rgb(38, 38, 46);
  --hoverButtonHome: rgb(255, 255, 255);
  --stepsIconAndText: rgb(255, 255, 255);
  --stepsIconAndTextReproved: rgb(0, 0, 0);
  --stepReproved: rgb(255, 215, 0);
}