.vjs-control-bar {
  background: linear-gradient(to top, #000000cc, transparent);
  background-color: transparent !important;
}

.vjs-progress-control {
  pointer-events: none;
}

.vjs-play-progress::before {
  display: none !important;
}

.vjs-volume-level {
  background-color: var(--themeColor) !important;
}

.vjs-progress-holder {
  height: 0.375rem !important;
}

.vjs-slider-horizontal,
.vjs-load-progress {
  border-radius: 0.375rem !important;
  overflow: hidden !important;
}

.vjs-play-progress {
  background-color: var(--stepReproved) !important;
}

.vjs-seek-to-live-control {
  display: none;
}

.vjs-slider-horizontal,
.vjs-load-progress,
.vjs-load-progress,
.vjs-load-progress div {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.vjs-play-control,
.vjs-volume-panel,
.vjs-progress-control,
.vjs-picture-in-picture-control,
.vjs-fullscreen-control {
  margin-top: -8px !important;
}

.video-js .vjs-control-bar {
  transition: opacity 0.3s ease;
}
