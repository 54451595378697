.swiper-cards-progress:hover .swiper-button-next{
    opacity: 100 !important;
}

.swiper-cards-progress:hover .swiper-button-next.swiper-button-disabled{
    opacity: 0 !important;
}

.swiper-button-next,.swiper-button-prev {
    width: 5% !important;
    height: 90% !important;
    margin-top: 0px !important;
    opacity: 0 !important;
    color: white; /* Can't have sure about this */
    @apply transition-all duration-300;
}
.swiper-button-next {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, var(--backgroundOne60) 100%);
}
.swiper-button-prev {
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, var(--backgroundOne60)100%);
}

.swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
}

.swiper-cards-progress:hover .swiper-button-prev{
    opacity: 100 !important;
}   

.swiper-cards-progress:hover .swiper-button-prev.swiper-button-disabled{
    opacity: 0 !important;
}


.swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
}